<template>
  <div>
    <b-table
      responsive
      :items="items"
      :busy="loading"
      :fields="patrimony.tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          @click="
            $router.push({
              name: 'show-patrimony',
              params: {
                slug: data.item.slug ? data.item.slug : '8787',
                id: data.item.id,
              },
            })
          "
          >{{ data.value }}</b-link
        >
      </template>
      <template v-slot:cell(company_architect)="data">
        <span>{{ data.item.architecte }}</span>
      </template>
      <template v-slot:cell(security_reference)="data">
        <span>{{ data.item.refsecurite }}</span>
      </template>

      <template v-slot:cell(responsable)="data">
        <TableResponsable :responsable="data.value" />
      </template>

      <template v-slot:cell(applications)="data">
        <div class="text-center">
          <b-badge
            variant="primary"
            pill
            @click="$emit('showGoal', data.item.id, data.item.name)"
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            ><span></span>{{ data.value ? data.value.length : "" }}</b-badge
          >
        </div>
      </template>

      <template v-slot:cell(composants)="data">
        <div class="text-center">
          <b-badge
            variant="primary"
            pill
            @click="$emit('showComposant', data.item.id, data.item.name)"
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            ><span></span>{{ data.value ? data.value.length : 0 }}</b-badge
          >
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="container ml-3 justify-content-end">
          <b-button
            v-if="data.value.includes('delete')"
            @click="deletePatrimoine(data.item.id)"
            variant="light"
            pill
            size="sm"
            style="width: 40px; height: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-delete"></i
          ></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableResponsable from "@/components/custom/TableResponsable";
import Swal from "sweetalert2";

export default {
  components: { TableResponsable },
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    patrimony: {
      tableFields: [
        { key: "name", label: "Patrimoine", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "composants",
          label: "Nb composants techniques",
          sortable: true,
        },
        {
          key: "applications",
          label: "Nb applications",
          sortable: true,
        },

        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
      tableItems: [
        {
          patrimony: "CRM Salesforce",
          responsable: {
            name: "S.E",
            full_name: "Steve EWORE",
            img: "https://source.unsplash.com/random/150x150",
          },
          company_architect: "En construction",
          security_reference: "S.I Finance",
          applications_number: 3,
          actions: ["show", "edit", "delete"],
        },
        {
          patrimony: "DSP 2",
          responsable: {
            name: "J.D",
            full_name: "John DOE",
            img: "https://source.unsplash.com/random/150x150",
          },
          company_architect: "En cours",
          security_reference: "S.I Support",
          applications_number: 2,
          actions: ["show", "edit", "delete"],
        },
        {
          patrimony: "Contract IARD",
          responsable: {
            name: "S.E",
            full_name: "Steve EWORE",
            img: "https://source.unsplash.com/random/150x150",
          },
          company_architect: "En production",
          security_reference: "S.I Distri",
          applications_number: 4,
          actions: ["show", "edit", "delete"],
        },
      ],
    },
  }),
  props: {
    items: Array,
    loading: Boolean,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    addFunction(item) {
      this.patrimony.tableItems[item.itemIndex].function = item.value;
    },
    deletePatrimoine(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.items = this.items.filter((data) => data.id !== id);
          this.$store.dispatch("patrimoine/deletePatrimoine", id).then(() => {
            Swal.fire("Supprimé!", "", "success");
          });
        }
      });
    },
  },
};
</script>

<style></style>
