<template>
  <section class="tables">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light">
            Patrimoine :
          </span>
          <span style="font-size: 22px">{{ patrimoine.name }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des composants techniques :</h4>
        <hr />
      </div>
    </div>
    <!-- {{ capacite.applications }} -->
    <!-- {{ COMPOSANT }} -->
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          responsive
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loading"
          bordered
          striped
          show-empty
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              :to="{
                name: 'show-composant',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              >{{ data.value }}
            </b-link>
          </template>
          <template v-slot:cell(capacites)="data">
            <div class="text-center">
              <b-badge
                variant="primary"
                pill
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                >{{ data.value ? data.value.length : 0 }}
              </b-badge>
            </div>
          </template>

          <template v-slot:cell(archictecteTechnique)="data">
            <TableResponsable :responsable="data.value ? data.value : ''" />
          </template>
          <template v-slot:cell(taux)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :value="data.value"
                :label="`${data.value}%`"
                :style="`background-color: ${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(applications)="data">
            <div class="text-center">
              <b-badge
                v-if="data.value.length"
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                @click="selectedapp(data.item)"
                pill
              >
                {{ data.value.length }}
              </b-badge>
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                v-else
                pill
              >
                {{ data.value.length }}
              </b-badge>
            </div>
          </template>

          <template v-slot:cell(versions)="data">
            <div class="text-center">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                v-if="data.value.length"
                @click="selectedversion(data.item)"
                pill
              >
                {{ data.value.length }}
              </b-badge>
              <b-badge
                v-else
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
              >
                {{ data.value.length }}
              </b-badge>
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <span>
              {{ data.value ? data.value : "-" }}
            </span>
          </template>
          <template v-slot:cell(editeur)="data">
            <span>
              {{ data.value ? data.value.name : "-" }}
            </span>
          </template>
          <template v-slot:cell(businessLine)="data">
            <div class="text-center">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
              >
                {{ data.value.length }}
              </b-badge>
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <span>{{ data.value }} </span>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        ></b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable";
import { mapGetters } from "vuex";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },
  props: {
    patrimoine: Object,
  },

  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "name", label: "Composant", sortable: true },
        { key: "editeur", label: "Editeur", sortable: true },
        { key: "status", label: "Status", sortable: true },
        {
          key: "type",
          label: "Type composant",
          sortable: true,
        },
        { key: "capacites", label: "Capacités", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        {
          key: "archictecteTechnique",
          label: "Architecte technique",
          sortable: true,
        },
      ],
      tableItems: [],
      loading: true,
    };
  },
  created() {
    this.$store
      .dispatch("patrimoine/fetchComposants", this.patrimoine.id)
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("patrimoine", ["COMPOSANT"]),

    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    COMPOSANT() {
      this.tableItems = this.COMPOSANT.composants;
    },
  },
};
</script>
