<template>
  <section class="tables">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"> Patrimoine : </span>
          <span style="font-size: 22px">{{ capacite.name }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des applications :</h4>
        <hr />
      </div>
    </div>
    <!-- {{ capacite.applications }} -->

    <div class="row">
      <div class="col-12">
        <b-table
          :items="capacite.applications"
          responsive
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          bordered
          striped
        >
          <template v-slot:cell(taux)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :style="`background:${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };font-size:8px;`"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(po)="data">
            <TableResponsable :responsable="data.value" />
          </template>

          <template v-slot:cell(ref_sa)="data">
            <div>
              <span style="text-decoration: underline; color: blue; cursor: pointer">
                SA{{ data.item.id }}
              </span>
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <span>{{ data.value || "-" }}</span>
            <!-- <b-badge>{{ data.value || "-" }}</b-badge> -->
          </template>
          <template v-slot:cell(patrimoine)="data">
            <span>{{ data.value.name }}</span>
          </template>
          <template v-slot:cell(si_capacity)="data">
            <span>{{ capacite.name }}</span>
          </template>

          <template v-slot:cell(actions)="data">
            <div class="d-flex">
              <b-button
                v-if="data.value.includes('show')"
                @click="$emit('showItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-eye"></i
              ></b-button>
              <b-button
                v-if="data.value.includes('edit')"
                @click="$emit('editItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-pencil-outline"></i
              ></b-button>
              <b-button
                v-if="data.value.includes('delete')"
                @click="$emit('deleteItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-delete"></i
              ></b-button>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        ></b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },
  props: {
    capacite: Object,
  },

  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "ref_sa", label: "Ref. SA", sortable: true },
        { key: "libellecour", label: "Libellé court SA", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "po", label: "PO", sortable: true },
        // { key: "techlead", label: "Tech Lead", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
      ],
      tableItems: [],
    };
  },
  computed: {
    rows() {
      return this.capacite.applications.length;
    },
  },
};
</script>
