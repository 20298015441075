<template>
  <div>
    <div class="row mt-3">
      <div class="col-12 d-flex mb-2 flex-wrap justify-content-between">
        <div class="my-auto d-flex mr-2">
          <h4 class="card-title mr-1 my-auto">Liste des patrimoines</h4>

          <DropDownCustom
            :items="['Tableau', 'Graphique']"
            @changeValue="
              (value) =>
                this.$store.dispatch('views/changePatrimoineActiveView', value)
            "
            :value="$store.state.views.patrimoineActiveView"
            class="mr-2 mb-2 mb-md-0"
          />
        </div>
        <div
          v-if="isManager || isArchitect || isAdmin || isSuper"
          class="flex-wrap mb-2 mb-md-0"
        >
          <div class="col-12 d-flex justify-content-end p-0">
            <button
              @click="$router.push({ name: 'add-patrimony' })"
              type="button"
              style="background-color: #198ae3"
              class="btn h1 d-flex text-white align-items-center"
            >
              <i class="mdi mdi-plus mr-2"></i>
              Créer patrimoine
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-0 bg-secondary" />

    <div class="row">
      <div class="col-12">
        <div v-if="$store.state.views.patrimoineActiveView == 0" class="card">
          <div class="card-body p-2">
            <patrimony-table
              :items="selectedData"
              :loading="loadingData"
              @showItem="$router.push({ name: 'show-patrimony' })"
              @editItem="$router.push({ name: 'edit-patrimony' })"
              @showGoal="showGoal"
              @showComposant="showComposant"
            />
          </div>
        </div>
        <graphic-view :items="selectedData" v-else />
      </div>
    </div>
    <div v-if="showGoals" to="modals-xyz-548">
      <Modal @close="showGoals = !showGoals">
        <goal-list :capacite="selectedCapacite" />
      </Modal>
    </div>

    <div v-if="showComposants" to="modals-xyz-548">
      <Modal @close="showComposants = !showComposants">
        <ComposantsList :patrimoine="selectedPatrimoine" />
      </Modal>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "@/components/custom/DropDown.vue";
import PatrimonyTable from "./components/PatrimonyTable.vue";
import GraphicView from "./components/GraphicView";
import { mapGetters } from "vuex";
import Modal from "@/components/custom/Modal.vue";
import GoalList from "./components/GoalList.vue";
import ComposantsList from "./components/ComposantsList.vue";

export default {
  components: {
    DropDownCustom,
    PatrimonyTable,
    GraphicView,
    GoalList,
    Modal,
    ComposantsList,
  },

  data: () => ({
    showGraf: 0,
    selectedData: [],
    loadingData: true,
    showGoals: false,
    showComposants: false,
    selectedCapacite: null,
    selectedPatrimoine: null,
  }),
  methods: {
    onValueChange(value) {
      this.showGraf = value;
    },
    showGoal(id, name) {
      this.$store.dispatch("patrimoine/fetchApplications", id).then(() => {
        if (this.APPLICATION.applications.length > 0) {
          var data = {
            applications: this.APPLICATION.applications,
            name: name,
          };
          this.selectedCapacite = data;
          this.showGoals = !this.showGoals;
        }
      });
    },
    showComposant(id, name) {
      this.selectedPatrimoine = {
        name: name,
        id: id,
      };
      this.showComposants = !this.showComposants;
    },
  },
  created() {
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
  },
  computed: {
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("patrimoine", ["APPLICATION"]),
    ...mapGetters(["isManager", "isArchitect", "isAdmin", "isSuper"]),
  },
  watch: {
    PATRIMOINES: function () {
      this.selectedData = this.PATRIMOINES.map((patr) => ({
        ...patr,
        actions: ["show", "delete"],
      }));
      this.loadingData = false;
    },
  },
};
</script>

<style>
.p-2 {
  padding: 0.5rem !important;
}
</style>
