<template>
  <div class="row card m-0">
    <div class="d-flex p-2 col-12">
      <DropDownCustom
        :items="[
          'Tous',
          'Processus',
          'Applications',
          'Composants techniques',
          'Cadres & Principes',
        ]"
        @changeValue="onValueChange"
        class="ml-auto mb-md-0"
      />
    </div>
    <div class="col-12 p-0">
      <div class="p-0 d-flex flex-column">
        <div
          v-if="!items.length"
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
        <div class="row col-12 p-0 mx-auto py-2" v-else>
          <div class="col-md-4 p-2" :key="index" v-for="(item, index) in items">
            <p class="pb-2 pt-3 h5">{{ item.name }}</p>
            <div style="height: calc(100% - 45.19px)">
              <div class="col-12 h-100 p-2 w-100" style="background: #f2edf3">
                <div class="text-white row p-2 rounded my-2 py-3 col-12 m-0">
                  <div
                    v-if="
                      !item.applications.length &&
                      !item.processus.length &&
                      !item.composants.length &&
                      !item.cadres.length
                    "
                    class="text-gray d-flex h2 text-center w-100"
                    style="height: 50px"
                  >
                    <!-- <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
                    <span class="my-auto ml-1 mr-auto"> Aucune donnée</span> -->
                  </div>

                  <!-- APPLICATION -->
                  <div
                    v-if="selectedProp === 0 || selectedProp === 2"
                    class="row w-100 mx-auto"
                  >
                    <div
                      class="col-12 col-md-6 px-1 py-0 mb-2"
                      :key="application.id"
                      v-for="application in item.applications"
                    >
                      <div class="bg-gradient-success rounded py-2 px-4">
                        <b-link
                          style="text-decoration: none; color: white"
                          :to="{
                            name: 'show-application',
                            params: {
                              id: application.id,
                              slug: application.slug || 'abcde',
                            },
                          }"
                          class="mb-0"
                          >{{ application.name }}</b-link
                        >
                      </div>
                    </div>
                  </div>
                  <!-- PROCESSUS -->
                  <div
                    v-if="selectedProp === 0 || selectedProp === 1"
                    class="row w-100 mx-auto"
                  >
                    <div
                      class="col-12 col-md-6 px-1 py-0 mb-2"
                      :key="process.id"
                      v-for="process in item.processus"
                    >
                      <div
                        class="py-2 px-4 bg-gradient-success arrow-shape"
                        style="text-decoration: none; color: white"
                      >
                        <b-link
                          style="text-decoration: none; color: white"
                          :to="{
                            name: 'show-application',
                            params: {
                              id: process.id,
                              slug: process.slug || 'abcde',
                            },
                          }"
                          class="mb-0"
                          >{{ process.name || process["@id"] }}</b-link
                        >
                      </div>
                    </div>
                  </div>

                  <!-- COMPOSANTS -->
                  <div
                    v-if="selectedProp === 0 || selectedProp === 3"
                    class="row w-100 mx-auto"
                  >
                    <div
                      class="col-12 col-md-6 px-1 py-0 mb-2"
                      :key="composant.id"
                      v-for="composant in item.composants"
                    >
                      <div
                        class="py-2 px-4 bg-gradient-primary"
                        style="text-decoration: none; color: white"
                      >
                        <b-link
                          style="text-decoration: none; color: white"
                          :to="{
                            name: 'show-application',
                            params: {
                              id: composant.id,
                              slug: composant.slug || 'abcde',
                            },
                          }"
                          class="mb-0"
                          >{{ composant.name || composant["@id"] }}</b-link
                        >
                      </div>
                    </div>
                  </div>

                  <!-- CADRES -->
                  <div
                    v-if="selectedProp === 0 || selectedProp === 4"
                    class="row w-100 mx-auto"
                  >
                    <div
                      class="col-12 col-md-6 px-1 py-0 mb-2"
                      :key="cadre.id"
                      v-for="cadre in item.cadres"
                    >
                      <div
                        class="py-2 px-4 bg-gradient-danger"
                        style="text-decoration: none; color: white"
                      >
                        <b-link
                          style="text-decoration: none; color: white"
                          :to="{
                            name: 'show-application',
                            params: {
                              id: cadre.id,
                              slug: cadre.slug || 'abcde',
                            },
                          }"
                          class="mb-0"
                          >{{ cadre.name || cadre["@id"] }}</b-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "@/components/custom/DropDown.vue";

export default {
  components: {
    DropDownCustom,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      selectedProp: 0,
    };
  },
  methods: {
    onValueChange(value) {
      this.selectedProp = value;
    },
  },
};
</script>

<style>
.arrow-shape {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}
</style>
